import { ObserverService } from '../observer-service/observer-service'
import { ElementExistsCallback } from '../model/element-exists-callback'
import { Disconnect } from '../model/disconnect'
import { noop } from '../model/noop'

export class ElementsExistsObserver {
  constructor(
    private readonly _document: Document,
    private readonly observerService: ObserverService
  ) {}

  observeOnce(
    selector: string,
    existsCallback: ElementExistsCallback
  ): Disconnect {
    if (this.queryElementAndCallCallback(selector, existsCallback)) {
      return { disconnect: noop }
    }
    const callback = this.createMutationCallback(selector, existsCallback)
    return this.observerService.registerAndObserve(
      this._document.body,
      callback,
      {
        attributes: false,
        subtree: true,
        childList: true,
      }
    )
  }

  private queryElementAndCallCallback(
    selector: string,
    existsCallback: ElementExistsCallback
  ) {
    const element = this._document.querySelectorAll<HTMLElement>(selector)
    if (element.length) {
      element.forEach((element) => existsCallback(element))
      return true
    }
    return false
  }

  private createMutationCallback(
    selector: string,
    existsCallback: ElementExistsCallback
  ): MutationCallback {
    return (mutationsList: MutationRecord[], observer: Disconnect) => {
      if (this.queryElementAndCallCallback(selector, existsCallback)) {
        observer.disconnect()
      }
    }
  }
}
