import { GlobalOnexConfig } from '@onex/model'

export const config: GlobalOnexConfig = {
  active: false,
  id: 'E3332',
  smoketestsIncludeOnex: false,
  localE2eTestsIncludeOnex: false,
  activeOnexConfig: {
    StatusQuo: {
      template: 'cookieBanner-onetrust.html',
      styleFile: 'styles-onetrust.scss',
    },
  },
}
