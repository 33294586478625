import { EventBusPort } from '../../shared/ports'
import { EventNames, TrackingPort } from '../ports'

export class TrackingViaEventBusAdapter<
  Features extends Record<string, [string]>,
> implements TrackingPort<Features>
{
  constructor(private readonly eventBus: EventBusPort) {}

  track(eventName: EventNames, feature: Features): void {
    return this.eventBus.emit(`tracking.bct.${eventName}`, feature)
  }
}
