import { logger } from '../shared'
import { bootstrap } from './bootstrap'

bootstrap().catch((error) => {
  logger.logError(
    new Error(`Failed to bootstrap onetrust-sdk`, {
      cause: error,
    })
  )
})
