import { ElementsEventObserver } from '../elements-event-observer/elements-event-observer'
import { Disconnect } from '../model/disconnect'
import { ElementEventCallback } from '../model/element-event-callback'

export class ElementsScrollObserver {
  constructor(private readonly elementsEventObserver: ElementsEventObserver) {}

  observeOnce(
    elementsSelector: string,
    onScrolledCallback: ElementEventCallback<'scroll'>
  ): Disconnect {
    return this.elementsEventObserver.observeOnce(
      elementsSelector,
      'scroll',
      onScrolledCallback
    )
  }
}
