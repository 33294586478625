import { FeatureTogglePort } from '../../shared/ports'

export const IS_ONETRUST_INTERNAL_HOSTING_ENABLED_TOGGLE_KEY =
  'EPR_ONETRUST_INTERNAL_HOSTING'

export function isOneTrustInternalHostingEnabled(
  featureTogglePort: FeatureTogglePort
) {
  return featureTogglePort(
    IS_ONETRUST_INTERNAL_HOSTING_ENABLED_TOGGLE_KEY,
    false
  )
}
