import { ObserverService } from '../observer-service/observer-service'
import { ElementsExistsObserver } from '../elements-exists-observer/elements-exists-observer'
import { isVisible } from './is-visible'
import { Disconnect } from '../model/disconnect'

export class ElementsVisibleObserver {
  constructor(
    private readonly _document: Document,
    private readonly observerService: ObserverService,
    private readonly elementExistsObserver: ElementsExistsObserver,
    private readonly isVisibleFn: (
      element?: Element | null
    ) => boolean = isVisible
  ) {}

  /**
 *
 *
 *
 */
  observe(
    elementSelector: string,
    changeCallback: (isVisible: boolean, observation: Disconnect) => void
  ): Disconnect {
    return this.elementExistsObserver.observeOnce(
      elementSelector,
      (element: Element) => {
        const callback = this.createMutationCallback(
          elementSelector,
          changeCallback
        )
        const observation = this.observerService.registerAndObserve(
          element,
          callback,
          {
            attributes: true,
            childList: false,
            subtree: false,
          }
        )
        changeCallback(this.isVisibleFn(element), observation)
        return observation
      }
    )
  }

  observeOnce(
    elementSelector: string,
    visibleCallback: (observation: Disconnect) => void
  ): Disconnect {
    return this.elementExistsObserver.observeOnce(
      elementSelector,
      (element: Element) => {
        if (this.isVisibleFn(element)) {
          visibleCallback({ disconnect: () => undefined })
          return { disconnect: () => undefined }
        }
        const callback = this.createOnceVisibleMutationCallback(
          elementSelector,
          visibleCallback
        )
        return this.observerService.registerAndObserve(element, callback, {
          attributes: true,
          childList: false,
          subtree: false,
        })
      }
    )
  }

  private createOnceVisibleMutationCallback(
    selector: string,
    onceVisibleCallback: (observation: Disconnect) => void
  ): MutationCallback {
    return this.createMutationCallback(selector, (isVisible, observer) => {
      if (isVisible) {
        onceVisibleCallback(observer)
        observer.disconnect()
      }
    })
  }

  private createMutationCallback(
    selector: string,
    visibleCallback: (isVisible: boolean, observation: Disconnect) => void
  ): MutationCallback {
    return (mutationsList: MutationRecord[], observer: Disconnect) => {
      const element = this._document.querySelector(selector)
      visibleCallback(this.isVisibleFn(element), observer)
    }
  }
}
