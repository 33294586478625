import { getConsentId } from './model/get-consent-id'
import { ConsentProviderPort } from '../ports'
import { cookie } from '@otto-ec/global-resources/cookie'

export class OnetrustConsentProvider implements ConsentProviderPort {
  constructor(private readonly oneTrust: OneTrust) {}

  AllowAll(): void {
    this.oneTrust.AllowAll()
  }

  OnConsentChanged(callback: () => void): void {
    this.oneTrust.OnConsentChanged(callback)
  }

  RejectAll(): void {
    this.oneTrust.RejectAll()
  }

  ToggleInfoDisplay(): void {
    this.oneTrust.ToggleInfoDisplay()
  }

  getConsentId(): null | string {
    return getConsentId()
  }

  cleanup(): void {
    cookie.remove('OptanonAlertBoxClosed')
  }
}
