import { eventQBus } from '@otto-ec/global-resources/event-q-bus'
import { EventBusPort, EventName } from '../../../ports'

export class EventQBusAdapter implements EventBusPort {
  once<T extends unknown[]>(event: EventName): Promise<T> {
    return eventQBus.once(event) as Promise<T>
  }

  emit<T extends unknown[]>(event: EventName, ...data: T): void {
    eventQBus.emit(event, ...data)
  }
}
