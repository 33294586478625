import { ModuleLoaderPort } from '../../../ports'

export class ModuleLoaderAdapter implements ModuleLoaderPort {
  initModuleByPriority(prio: number, initCallback: () => void): void {
    return window.o_global.eventLoader.onAllPreloadScriptsLoaded(
      prio,
      initCallback
    )
  }
}
