export function isVisible(
  element?: Element | null,
  getComputedStyle: (
    element: Element
  ) => CSSStyleDeclaration = window.getComputedStyle
): boolean {
  if (!element) {
    return false
  }

  const { display, visibility, opacity } = getComputedStyle(element)
  if (display === 'none' || visibility === 'hidden' || opacity === '0') {
    return false
  }

  const { height, width } = element.getBoundingClientRect()
  if (height === 0 || width === 0) {
    return false
  }
  return true
}
