import {
  ElementsClickObserver,
  ElementsEventObserver,
  ElementsExistsObserver,
  ElementsScrollObserver,
  ElementsVisibleObserver,
  MutationObserverFactory,
  ObserverService,
  TrackingViaEventBusAdapter,
} from './adapters'
import {
  OnetrustCookieBanner,
  TrackingFeatures,
  TrackingService,
  PerformanceTrackingService,
} from './core'
import {
  apps,
  cmp,
  eventBus,
  logger,
  showConsentBannerUseCase,
} from '../shared'

declare global {
  /*                                 */
  interface Window {
    cookieBanner: OnetrustCookieBanner
    o_cookieBanner: OnetrustCookieBanner
  }
}
export const bootstrap = async () => {
  const mutationObserverFactory: MutationObserverFactory = {
    create: (callback: MutationCallback) => new MutationObserver(callback),
  }
  const observerService = new ObserverService(mutationObserverFactory)

  const elementsExistsObserver = new ElementsExistsObserver(
    document,
    observerService
  )
  const elementsEventObserver = new ElementsEventObserver(
    document,
    elementsExistsObserver
  )
  const elementsClickObserver = new ElementsClickObserver(elementsEventObserver)
  const elementsScrollObserver = new ElementsScrollObserver(
    elementsEventObserver
  )
  const elementsVisibleObserver = new ElementsVisibleObserver(
    document,
    observerService,
    elementsExistsObserver
  )

  const trackingViaEventBusAdapter =
    new TrackingViaEventBusAdapter<TrackingFeatures>(eventBus)
  const trackingService = new TrackingService(trackingViaEventBusAdapter)

  const performanceTrackingService = new PerformanceTrackingService(
    logger,
    0.001
  )
  performanceTrackingService.init()

  const onetrustCookieBanner = new OnetrustCookieBanner(
    document,
    cmp,
    apps,
    eventBus,
    elementsClickObserver,
    elementsScrollObserver,
    elementsVisibleObserver,
    trackingService,
    performanceTrackingService
  )

  onetrustCookieBanner.registerEventListener()
  onetrustCookieBanner.registerLinkTrackingListener()

  window.cookieBanner = onetrustCookieBanner
  window.o_cookieBanner = onetrustCookieBanner

  await showConsentBannerUseCase.run()
}
