import { EventNames, TrackingPort } from '../../ports'
import {
  CookieBannerEvents,
  CookieBannerFeatureList,
  CookieBannerMoveEvent,
  PreferenceCenterEvents,
  PreferenceCenterFeatureList,
  TrackingFeatures,
} from './tracking-features'

export class TrackingService {
  constructor(private trackingPort: TrackingPort<TrackingFeatures>) {}

  trackOTFirstLayerView(): void {
    this.trackPageImpressionView()
  }

  trackOTFirstLayerAgree(): void {
    this.trackCookieBannerEvent('agree')
  }

  trackOTFirstLayerDisagree(): void {
    this.trackCookieBannerEvent('disagree')
  }

  trackOTFirstLayerSettings(): void {
    this.trackCookieBannerEvent('settings')
  }

  trackOTFirstLayerScroll(): void {
    this.trackCookieBannerEvent(`read_policy`)
  }

  trackOTFirstLayerTextLink(link: string): void {
    return this.trackCookieBannerMove(`textLink_${link}`)
  }

  trackOTPreferenceCenterView(): void {
    this.trackPreferenceCenterEvent('view')
  }

  trackOTPreferenceCenterAgreeAll(): void {
    this.trackPreferenceCenterEvent('agree_All')
  }

  trackOTPreferenceCenterDisagreeAll(): void {
    this.trackPreferenceCenterEvent('disagree_All')
  }

  trackOTPreferenceCenterAgreeMySelection(): void {
    this.trackPreferenceCenterEvent('agree_MySelection')
  }

  trackPageImpressionView(): void {
    this.trackingPort.track(
      EventNames.PageImpression,
      this.createCookieBannerFeatureList('view')
    )
  }

  private trackCookieBannerEvent(event: CookieBannerEvents): void {
    this.trackingPort.track(
      EventNames.Event,
      this.createCookieBannerFeatureList(event)
    )
  }

  private trackCookieBannerMove(event: CookieBannerMoveEvent): void {
    this.trackingPort.track(
      EventNames.Move,
      this.createCookieBannerFeatureList(event)
    )
  }

  private createCookieBannerFeatureList(
    event: CookieBannerEvents | CookieBannerMoveEvent
  ): CookieBannerFeatureList {
    return { ot_CookieBanner: [event] }
  }

  private trackPreferenceCenterEvent(event: PreferenceCenterEvents): void {
    const featureList: PreferenceCenterFeatureList = {
      ot_CookieBannerSettings: [event],
    }

    this.trackingPort.track(EventNames.Event, featureList)
  }
}
