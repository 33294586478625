import { activeVariantConfig, logger } from '../shared'

const DEFAULT_STYLE_FILE = `styles-onetrust.scss`

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                                 */
/*                                                                                                  */
const SCSS_MODULES: Record<string, () => Promise<string>> = import.meta.glob(
  ['./scss/*.scss'],
  {
    query: '?inline',
    import: 'default',
  }
)

activeVariantConfig
  .then(getStyleFile)
  .then(importStyles)
  .then(appendStyleToBody)
  .catch((error) => {
    logger.logError(error)
  })

function getStyleFile(
  onexConfig: {
    styleFile: string | undefined
  } | null
) {
  return onexConfig?.styleFile ?? DEFAULT_STYLE_FILE
}

function importStyles(stylesScss: string): Promise<string> {
  return SCSS_MODULES[`./scss/${stylesScss}`]()
}

function appendStyleToBody(styleCode: string) {
  const style = document.createElement('style')
  style.innerHTML = styleCode
  /*                                                                                        */
  document.body.appendChild(style)
}
