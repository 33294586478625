import { OnetrustConsentProvider } from './onetrust-consent.provider'
import { createOptanonWrapper } from './optanon-wrapper/optanon-wrapper'
import { setVariantCookie } from './model/set-variant-cookie'
import { ConsentProviderConfig, LoadConsentProviderPort } from '../ports'
import {
  EnvironmentConfig,
  LoggingServicePort,
  RetryPort,
} from '../../shared/ports'
import { getOnetrustSdkSrc } from './model/get-onetrust-sdk-src'
import { loadOnetrustSdk } from './load-one-trust-sdk/load-onetrust-sdk'

const MAX_ATTEMPTS = 2

export const loadOneTrustConsentProviderFactory = (
  logger: LoggingServicePort,
  envConfig: EnvironmentConfig,
  isOneTrustInternalHostingEnabled: boolean,
  retryWrapper: RetryPort
) => {
  const loadOnetrustConsentProvider: LoadConsentProviderPort = async (
    config: ConsentProviderConfig,
    onetrustVariantId?: string
  ) => {
    const oneTrustPromise = new Promise<OneTrust>((resolve) => {
      window.OptanonWrapper = createOptanonWrapper(resolve)
    })

    if (onetrustVariantId) {
      setVariantCookie(onetrustVariantId, envConfig.stage)
      /*                                                                        */
    }

    const oneTrustSrc = getOnetrustSdkSrc(
      config.onetrust.onetrustSiteId,
      envConfig.stage,
      isOneTrustInternalHostingEnabled
    )

    const checkScriptIntegrity = !isOneTrustInternalHostingEnabled

    let failedAttempts = 0

    await retryWrapper.run<void>(
      () => loadOnetrustSdk(config, oneTrustSrc, checkScriptIntegrity),
      MAX_ATTEMPTS,
      () => failedAttempts++
    )
    if (failedAttempts > 0) {
      logger.logMessage(
        `Successfully loaded OneTust-SDK after ${failedAttempts}/${MAX_ATTEMPTS} attempts.`
      )
    }
    return new OnetrustConsentProvider(await oneTrustPromise)
  }

  return loadOnetrustConsentProvider
}
